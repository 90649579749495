import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout/layout";
import SEO from "../../components/seo/seo";

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Blog = () => {
  return (
    <Layout>
      <SEO />
      <div>blog/index works!</div>
    </Layout>
  );
};

export default Blog;
